$font-family-sans-serif: roboto;
$primary: #00aff0;
$secondary: #f58634;
$border-radius: 0.6rem;

@import "bootstrap/scss/bootstrap";
@import "select2/src/scss/core";
@import "select2-bootstrap-5-theme/src/include-all";
@import "./spinner";
@import "@fontsource/roboto/scss/mixins";

@include fontFace($weight: 400, $fontDir: "../fonts");
